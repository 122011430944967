<template>
    <section class="section">
        <div class="row py-5">
            <div class="col-md-6 offset-md-9 col-12 offset-6">
                <img src="/assets/logo-vertical.png" alt="ヒトツキ" />
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-10 offset-md-7">
                <h2 class="section-title justify-content-center text-primary">60日間無料トライアル</h2>
                <div class="p-3">クレジットカード等の登録は不要です。本申込を行わない限り、トライアル期間が終了しても、自動的に課金されることはありません。</div>
            </div>
        </div>
        <form @submit.prevent="signin">
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">会社名</label>
                    <form-input v-model="param.company_name" required="true"></form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">お名前</label>
                    <form-input v-model="param.user_name" required="true"></form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">メールアドレス</label>
                    <input type="email" class="form-control" v-model="param.email" required="true">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">会社電話番号</label>
                    <form-input v-model="param.phone" required="true" pattern="0\d{1,4}-\d{1,4}-\d{4}" title="半角数字・ハイフンあり"></form-input>
                    <small>半角数字・ハイフンあり</small>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8 offset-md-8">
                    <label class="form-label">希望企業コード</label>
                    <form-input v-model="param.company_code" required="true" pattern="[A-Za-z0-9]{3,20}" title="半角英数字3〜20文字"></form-input>
                    <small>半角英数字3〜20文字。ログイン時に使用します。会社名の略称など、覚えやすく短いものをお勧めします。</small>
                </div>
            </div>

            <div class="mt-5 text-center">
                <button type="submit" class="btn btn-primary btn-lg">無料トライアル開始</button>
            </div>
        </form>
    </section>
</template>

<script>
import guestHttp from '@/http/guest'
import FormInput from '@/components/forms/FormInput';

export default {
    name: 'Signup',
    components: {
        FormInput,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            param: {
                company_name: null,
                user_name: null,
                email: null,
                phone: null,
                company_code: null,
            },
        }
    },
    mounted() {

    },
    methods: {
        signin() {
            this.startScreenLoading();
            guestHttp.get('/csrf')

            .then((response) => {
                const headers = {
                    'X-CSRF-TOKEN': response.data.token
                }
                guestHttp.post('/signup', this.param, { headers })
                .then(() => {
                    this.showMessage('ご登録ありがとうございます。ご登録のメールアドレス宛にメールを送信しました。メールに記載されている仮パスワードでログインしてください。');
                    this.$router.push({name: 'Signin'});
                })
                .finally(() => {
                    this.endScreenLoading();
                });
            });

        },
    }
}
</script>

<style scoped>

</style>
