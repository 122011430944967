import axios from 'axios'

const guestHttp = axios.create({
    baseURL: process.env.VUE_APP_API_URI
})

guestHttp.interceptors.request.use(
    async (config) => {
        config.headers['X-Requested-With'] = "XMLHttpRequest";
        return config;
    }
)

guestHttp.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 400) {
            let message_detail = {
                title: 'エラー',
                message: error.response.data.message,
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 403) {
            let message_detail = {
                title: '権限エラー',
                message: 'この操作をする権限がありません。必要な場合、管理者に権限の変更を依頼してください。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 404) {
            let message_detail = {
                title: '404エラー',
                message: 'データが見つかりません。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 418) {
            let message_detail = {
                title: '有効期限切れ',
                message: 'ページを再読み込みしてください。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorMessages = [];
            Object.keys(errors).forEach((key) => {
                let array_order = key.match(new RegExp(/\.([0-9]+)\./));
                if (array_order) {
                    //ネストしている場合は何行目が間違っているか表示する（多重ネストは非対応です）
                    let row_number = parseInt(array_order[1]) + 1;
                    errorMessages.push(`${row_number}行目の${errors[key]}`);
                } else {
                    errorMessages.push(errors[key]);
                }
            });

            let message_detail = {
                title: '入力内容に誤りがあります',
                message: errorMessages.join("\n"),
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else {
            let message_detail = {
                title: 'エラー',
                message: 'システムエラーが発生しました',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);
        }

        return Promise.reject(error);
    }
)

export default guestHttp
